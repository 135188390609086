
import { Watch } from 'vue-property-decorator';
import { mixins, Options, Vue } from 'vue-class-component';
import { BaseAudioRoom } from './baseAudioRoom';
import { AudioUserVM, ChatMessageVM } from '@/signalingHubVM';
import { ExhibitionClient } from '@/services/services';
import * as OM from '@/model';
import { AudioRoomServices } from '@/services/AudioRoom/AudioRoomServices';

@Options({
    components: {
    }
})
export default class AudioRoom extends mixins(BaseAudioRoom) {

    exhibitionIdentifier: string = "";
    chatMessages: ChatMessageVM[] = [];
    token: string = "";
    exhibition: OM.GetExhibitionVm = new OM.GetExhibitionVm();
    
    created(){
        this.exhibitionIdentifier = this.$route.params.exhibitionIdentifier as string;
        ExhibitionClient.showDetail(this.exhibitionIdentifier)
        .then( x => {
            this.exhibition = x;
        })
    }

    mute(){
        this.localStream.getAudioTracks()[0].enabled = false;
    }

    unmute(){
        this.localStream.getAudioTracks()[0].enabled = true;
    }

    initVariable(){
        this.token = "";
        this.room = this.$route.params.roomname as string;
        this.localVideo = this.$refs.localVideoRef;
        this.remoteVideo = this.$refs.remoteVideoRef;
    }

    roomCreated(connectionId: string){
        this.isInitiator = true;
        console.log("roomCreated", connectionId);
    }

    roomJoined(connectionId: string, name: string){
        this.isChannelReady = true;

        var userVm: AudioUserVM = {
            canTalk: false,
            requestedToTalk: false,
            connectionId: connectionId,
            name: name
        };
        
        this.users.push(userVm);
        
        console.log("roomJoined", connectionId);
    }

    roomLog(message: string){
        // console.log("roomLog", message);
    }

    roomChatMessage(value: ChatMessageVM){
        this.chatMessages.push(value);
        console.log("roomChatMessage", value);
    }

    roomMessage(message: any){
        if (message === 'got user media') {
            this.initiateCall();

        } else if (message.type === 'offer') {
            if (!this.isStarted) {
                this.initiateCall();
            }
            
            this.peerConnection.setRemoteDescription(new RTCSessionDescription(message));
            this.sendAnswer();

        } else if (message.type === 'answer' && this.isStarted) {
            this.peerConnection.setRemoteDescription(new RTCSessionDescription(message));

        } else if (message.type === 'candidate' && this.isStarted) {
            this.addIceCandidate(message);

        } else if (message.type === 'bye' && this.isStarted) {
            this.handleRemoteHangup(message.connectionId);
        }
    }

    async sendMute(connectionId){
        await AudioRoomServices.sendMute(connectionId);

        var found = this.users.find( el => el.connectionId == connectionId);
        var index = this.users.indexOf(found);
        this.users[index].canTalk = false;
    }

    async sendUnmute(connectionId){
        await AudioRoomServices.sendUnmute(connectionId);
        var found = this.users.find( el => el.connectionId == connectionId);
        var index = this.users.indexOf(found);
        this.users[index].canTalk = true;
    }

    async quit(){
        if(!this.isInitiator){
            await this.hangup();
            return;
        }

        await AudioRoomServices.sendCloseRoom(this.room);
        await this.hangup();
    }

    async closeRoom(){
        await this.hangup();
    }
}
