import { iceServers } from '@/config';
import { AudioRoomServices } from '@/services/AudioRoom/AudioRoomServices';
import { WebrtcUtils } from '@/services/AudioRoom/Webrtc-utils.service';
import { AudioUserVM, ChatMessageVM } from '@/signalingHubVM';
import { Options, Vue } from 'vue-class-component';

export abstract class BaseAudioRoom extends Vue {

    //https://github.com/dalkofjac/webrtc-tutorials/blob/master/WebRTCWebApp/src/app/components/session-call/session-call.component.ts

    abstract token: string;

    //init token, room, localVideo element, remoteVideo element
    abstract initVariable();

    abstract roomCreated(connectionId: string);
    abstract roomJoined(connectionId: string, name: string);
    abstract mute();
    abstract unmute();
    abstract roomLog(message: string);
    abstract roomMessage(message: any);
    abstract roomChatMessage(message: ChatMessageVM);
    abstract closeRoom();

    localVideo: any = null;
    remoteVideo: any = null;

    localStream: MediaStream = null;
    remoteStream: MediaStream = null;

    room: string = "";
    peerConnection: RTCPeerConnection = null;

    isInitiator: boolean = false;
    isChannelReady: boolean = false;
    isStarted: boolean = false;

    users: AudioUserVM[] = [];

    created() {
    }
    
    mounted(){
        this.initVariable();
    }

    async start(){
        // #1 connect to signaling server
        await AudioRoomServices.createOrJoinRoom(this.room, this.token, {}, this.roomCreated, this.roomJoined, this.roomLog, this.roomMessage, this.roomChatMessage, this.mute, this.unmute, this.closeRoom);

        // #3 get media from current client
        this.getUserMedia();
    }

    getUserMedia(): void {
        navigator.mediaDevices.getUserMedia({
            audio: true,
            video: false
        })
        .then((stream: MediaStream) => {
            this.addLocalStream(stream);

            this.sendMessage('got user media');

            if (this.isInitiator)
                this.initiateCall();
            else 
                this.localStream.getAudioTracks()[0].enabled = false;
        })
        .catch((e) => {
            console.error('getUserMedia() error: ' + e.name + ': ' + e.message);
        });
    }

    initiateCall(): void {
        console.log('Initiating a call.');

        if (!this.isStarted && this.localStream && this.isChannelReady) {
            this.createPeerConnection();

            this.peerConnection.addTrack(this.localStream.getAudioTracks()[0], this.localStream);

            this.isStarted = true;
            
            if (this.isInitiator)
                this.sendOffer();
        }
    }

    createPeerConnection(): void {
        console.log('Creating peer connection.');
        try {
            // if (useWebrtcUtils) {
                this.peerConnection = WebrtcUtils.createPeerConnection(iceServers, 'unified-plan', 'balanced', 'all', 'require', null, [], 0);
            // } else {
            //     this.peerConnection = new RTCPeerConnection({
            //     iceServers: environment.iceServers,
            //     sdpSemantics: 'unified-plan'
            //     } as RTCConfiguration);
            // }

            this.peerConnection.onicecandidate = (event: RTCPeerConnectionIceEvent) => {
                if (event.candidate) {
                    this.sendIceCandidate(event);
                } else {
                    console.log('End of candidates.');
                }
            };

            this.peerConnection.ontrack = (event: RTCTrackEvent) => {
                if (event.streams[0]) {
                    this.addRemoteStream(event.streams[0]);
                }
            };

            // if (useWebrtcUtils) {
            this.peerConnection.oniceconnectionstatechange = () => {
                if (this.peerConnection?.iceConnectionState === 'connected') {
                    WebrtcUtils.logStats(this.peerConnection, 'all');
                } else if (this.peerConnection?.iceConnectionState === 'failed') {
                    WebrtcUtils.doIceRestart(this.peerConnection, this);
                }
            }
            // }
        } catch (e: any) {
            console.error('Failed to create PeerConnection.', e.message);
            return;
        }
    }

    sendOffer(): void {
        console.log('Sending offer to peer.');

        this.addTransceivers();

        this.peerConnection.createOffer()
        .then((sdp: RTCSessionDescriptionInit) => {
            let finalSdp = sdp;
            // if(useWebrtcUtils) {
            finalSdp = WebrtcUtils.changeBitrate(sdp, '1000', '500', '6000');
            if(WebrtcUtils.getCodecs('audio').find(c => c.indexOf(WebrtcUtils.OPUS) !== -1))
                finalSdp = WebrtcUtils.setCodecs(finalSdp, 'audio', WebrtcUtils.OPUS);

            if(WebrtcUtils.getCodecs('video').find(c => c.indexOf(WebrtcUtils.H264) !== -1))
                finalSdp = WebrtcUtils.setCodecs(finalSdp, 'video', WebrtcUtils.H264);
            
            // }
            this.peerConnection.setLocalDescription(finalSdp);
            this.sendMessage(sdp);
        });
    }

    sendAnswer(): void {
        console.log('Sending answer to peer.');
        this.addTransceivers();
        this.peerConnection.createAnswer()
        .then((sdp) => {
            this.peerConnection.setLocalDescription(sdp);
            this.sendMessage(sdp);
        });
    }

    addIceCandidate(message: any): void {
        console.log('Adding ice candidate.');

        const candidate = new RTCIceCandidate({
            sdpMLineIndex: message.label,
            candidate: message.candidate
        });

        this.peerConnection.addIceCandidate(candidate);
    }

    sendIceCandidate(event: RTCPeerConnectionIceEvent): void {
        console.log('Sending ice candidate to remote peer.');
        
        this.sendMessage({
            type: 'candidate',
            label: event.candidate.sdpMLineIndex,
            id: event.candidate.sdpMid,
            candidate: event.candidate.candidate
        });
    }

    sendMessage(message): void {
        AudioRoomServices.sendMessage(this.room, this.token, message);
    }

    addTransceivers(): void {
        console.log('Adding transceivers.');
        const init = { direction: 'recvonly', streams: [], sendEncodings: [] } as RTCRtpTransceiverInit;
        this.peerConnection.addTransceiver('audio', init);
        // this.peerConnection.addTransceiver('video', init);
    }

    addLocalStream(stream: MediaStream): void {
        console.log('Local stream added.');
        this.localStream = stream;
        this.localVideo.srcObject = this.localStream;
        this.localVideo.muted = 'muted';
    }

    addRemoteStream(stream: MediaStream): void {
        console.log('Remote stream added.');
        this.remoteStream = stream;
        this.remoteVideo.srcObject = this.remoteStream;
        this.remoteVideo.nativeElement.muted = 'muted';
    }

    async hangup() {
        console.log('Hanging up.');
        this.stopPeerConnection();

        // this.sendMessage('bye');

        await AudioRoomServices.leaveRoom(this.room, this.token);
    }

    handleRemoteHangup(connectionId: string): void {
        console.log('Session terminated by remote peer.');
        var found = this.users.find(x => x.connectionId == connectionId);
        var index = this.users.indexOf(found);
        this.users.splice(index, 1);
        // this.stopPeerConnection();
        // this.isInitiator = true;
        console.log(connectionId, 'Remote client has left the call.');
    }

    stopPeerConnection(): void {
        this.isStarted = false;
        this.isChannelReady = false;

        if (this.peerConnection) {
            this.peerConnection.close();
            this.peerConnection = null;
        }
    }

    async beforeUnmount() {
        await this.hangup();
        if (this.localStream && this.localStream.active) {
            this.localStream.getTracks().forEach((track) => { track.stop(); });
        }
        if (this.remoteStream && this.remoteStream.active) {
            this.remoteStream.getTracks().forEach((track) => { track.stop(); });
        }
    }
}