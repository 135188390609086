import { CommonServices } from '../CommonServices';
import * as OM from '@/Model';
import * as signalR from "@microsoft/signalr";
import { baseUrl } from '@/config';
import { SignalrServices } from '../SignalRServices';
import { ChatMessageVM } from '@/signalingHubVM';

class _AudioRoomServices {
    
    private connection: signalR.HubConnection;

    async createOrJoinRoom(roomName: string, token:string,  params: { [key: string]: any }, created: any, joined: any, log: any, message: any, chatMessage: any, mute: any, unmute: any, closeRoom: any){

        let eventListeners = {
            log: (val: string) => {
                log(val);
            },
            created: (val: string) => {
                created(val);
            },
            joined: (connectionId: string, name: string) => {
                joined(connectionId, name);
            },
            message: (val: any) => {
                message(val);
            },
            chatMessage: (val: ChatMessageVM) => {
                chatMessage(val);
            },
            mute: () => {
                mute();
            },
            unmute: () => {
                unmute();
            },
            closeRoom: () => {
                closeRoom();
            }
        }

        this.connection = await SignalrServices.connectToHub("hubs/signaling", token, params, eventListeners);
        this.connection.invoke('CreateOrJoinRoom', roomName)
    }

    leaveRoom(roomName: string, token: string): Promise<void>{
        if(!this.connection)
            return null;

        let prom = new Promise<void>((resolve, reject) => {
            
            this.connection.invoke('LeaveRoom', roomName)
            .then( x => {
                resolve();
            })
            .catch( err => {
                reject(err);
            })
        })

        return prom;
    }

    sendMessage(roomName: string, token: string, message: any): Promise<void>{
        if(!this.connection)
            return null;

        let prom = new Promise<void>((resolve, reject) => {
            
            this.connection.invoke('SendMessage', message, roomName)
            .then( x => {
                resolve();
            })
            .catch( err => {
                reject(err);
            })
        })

        return prom;
    }

    sendMute(connectionId: string): Promise<void>{
        if(!this.connection)
            return null;

        let prom = new Promise<void>((resolve, reject) => {
            
            this.connection.invoke('SendMute', connectionId)
            .then( x => {
                resolve();
            })
            .catch( err => {
                reject(err);
            })
        })

        return prom;
    }

    sendUnmute(connectionId: string): Promise<void>{
        if(!this.connection)
            return null;

        let prom = new Promise<void>((resolve, reject) => {
            
            this.connection.invoke('SendUnmute', connectionId)
            .then( x => {
                resolve();
            })
            .catch( err => {
                reject(err);
            })
        })

        return prom;
    }

    sendCloseRoom(roomName: string): Promise<void>{
        if(!this.connection)
            return null;

        let prom = new Promise<void>((resolve, reject) => {
            
            this.connection.invoke('CloseRoom', roomName)
            .then( x => {
                resolve();
            })
            .catch( err => {
                reject(err);
            })
        })

        return prom;
    }
}

export let AudioRoomServices = new _AudioRoomServices();