import { CommonServices } from './CommonServices';
import * as OM from '@/Model';
import * as signalR from "@microsoft/signalr";
import { baseUrl } from '@/config';

class _SignalrServices {
    
    connectToHub(hubPath: string, token:string,  params: { [key: string]: any }, eventsListener: { [key: string]: (...args: any[]) => void }) : Promise<signalR.HubConnection> {

        let prom = new Promise<signalR.HubConnection>((resolve, reject) => {
            
            let queryParams = Object.keys(params).length > 0 ? '?' : '';
            for(let prop in params){
                queryParams += prop + '=' + params[prop];
            }
            const connection = new signalR.HubConnectionBuilder()
                .withUrl(baseUrl + hubPath + queryParams, {
                    withCredentials: false,
                    accessTokenFactory: () => token 
                })
                .configureLogging(signalR.LogLevel.Information)
                .build();
            for(let prop in eventsListener){
                connection.on(prop, eventsListener[prop]);
            }

            connection.onclose(() => {
                this.startConnection(connection);
            });

            this.startConnection(connection)
            .then( x => {
                resolve(connection);
            })
        });

        return prom;
    }

    private startConnection(connection: signalR.HubConnection): Promise<boolean> {
        try {
            let prom = new Promise<boolean>((resolve, reject) => {
                connection.start()
                .then(x => {
                    resolve(true)
                    // console.log("SignalR Connected.");
                })
                .catch(err => {
                    // console.log(err);
                    setTimeout(() => this.startConnection(connection), 5000);
                })
            })

            return prom;
        } catch (err) {
            // console.log(err);
            setTimeout(() => this.startConnection(connection), 5000);
        }
    };

    disconnect(connection: signalR.HubConnection){
        (<any[]>(<any>connection)._closedCallbacks).splice(0);
        connection.stop();
    }

}

export let SignalrServices = new _SignalrServices();